import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";
import { generateQueryParam } from "utils/urlEncode";

const initialState = {
  games: null,
  metadataGamesPage: null,
  gameConfigsOfUser: null,
};

export const gamesSlice = createSlice({
  name: "games",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGames.pending, (state, action) => {})
      .addCase(getGames.fulfilled, (state, action) => {
        state.games = action.payload.data;
        state.metadataGamesPage = action.payload.meta;
      })
      .addCase(getGames.rejected, (state, action) => {})
      .addCase(getGameConfigsOfUser.pending, (state, action) => {})
      .addCase(getGameConfigsOfUser.fulfilled, (state, action) => {
        state.gameConfigsOfUser = action.payload.data;
      })
      .addCase(getGameConfigsOfUser.rejected, (state, action) => {});
  },
});

export const getGames = createAsyncThunk("games/getGames", async (params) => {
  try {
    const queryStringPart = generateQueryParam(params);
    const respone = await axiosInstance.get(`/games${queryStringPart}`, {});
    return respone.data;
  } catch (error) {
    throw new Error(error);
  }
});

export const getGameConfigsOfUser = createAsyncThunk(
  "games/getGameConfigsOfUser",
  async () => {
    try {
      const respone = await axiosInstance.get(`/game-configs`);
      return respone.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateGameConfigOfUser = createAsyncThunk(
  "games/updateGameConfigOfUser",
  async ({ gameId, config }) => {
    try {
      const respone = await axiosInstance.patch(
        `/game-configs/${gameId}`,
        config
      );
      return respone.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export default gamesSlice.reducer;
