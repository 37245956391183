import { getMe } from "appdata/myself/myselfSlice";
import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Dashboard from "pages/dashboard/Dashboard";
import GamePlay from "pages/game/GamePlay";
import Login from "pages/login/Login";
import MasterLayout from "layouts/MasterLayout";

// Hàm kiểm tra nếu user đã đăng nhập
const isAuthenticated = () => {
  return !!localStorage.getItem("mi01_auth"); // Trả về true nếu có playFabUser
};

// ProtectedRoute component để kiểm tra quyền truy cập
const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    // Chuyển hướng đến login nếu không có playFabUser
    return <Navigate to="/login" />;
  }

  // Hiển thị nội dung nếu đã đăng nhập
  return children;
};

function AppRoutes() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  if (!window._env_) {
    window._env_ = {};
  }

  // Sau đó gán giá trị cho thuộc tính REACT_APP_API_URL
  window._env_.REACT_APP_API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("mi01_la") ?? "ja");
    if (isAuthenticated()) {
      dispatch(getMe());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
        <Route path="/login" element={<Login />}></Route>
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/gameplay" element={<GamePlay />} />
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
