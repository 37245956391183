import { useEffect, useRef } from "react";
import ZoomIcon from "assets/icons/zoom.png";
import styles from "./GamePlay.module.scss";

function GamePlay() {
  const searchParams = new URLSearchParams(window.location.search);
  const game = searchParams.get("game");
  const userID = searchParams.get("userID");
  const endPoint = window._env_.REACT_APP_API_URL + "/api/v1";

  const wrapperIframeRef = useRef(null);
  const iframeRef = useRef(null);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      // Enter fullscreen
      if (wrapperIframeRef.current) {
        if (wrapperIframeRef.current.requestFullscreen) {
          wrapperIframeRef.current.requestFullscreen();
        } else if (wrapperIframeRef.current.mozRequestFullScreen) {
          // Firefox
          wrapperIframeRef.current.mozRequestFullScreen();
        } else if (wrapperIframeRef.current.webkitRequestFullscreen) {
          // Chrome, Safari, and Opera
          wrapperIframeRef.current.webkitRequestFullscreen();
        } else if (wrapperIframeRef.current.msRequestFullscreen) {
          // IE/Edge
          wrapperIframeRef.current.msRequestFullscreen();
        }
      }
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // Chrome, Safari, and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // IE/Edge
        document.msExitFullscreen();
      }
    }
  };

  useEffect(() => {
    // Khi iframe đã tải xong, gửi endPoint tới iframe
    const iframe = iframeRef.current;

    if (iframe) {
      const sendMessageToIframe = () => {
        iframe.contentWindow.postMessage({ endPoint, userId: userID }, "*");
      };

      // Đảm bảo iframe đã tải xong trước khi gửi message
      iframe.addEventListener("load", sendMessageToIframe);

      return () => {
        iframe.removeEventListener("load", sendMessageToIframe);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframeRef]);

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        padding: "2vw 4vw",
        position: "relative",
      }}
    >
      <div
        style={{
          borderRadius: "1vw",
          display: "flex",
          overflow: "hidden",
          flex: "1",
          justifyContent: "center",
        }}
        ref={wrapperIframeRef}
      >
        <iframe
          ref={iframeRef}
          title="WebGL Game"
          src={`/${game}/index.html`}
          style={{
            border: "none",
            aspectRatio: "16/9",
            borderRadius: "1vw",
          }}
          allowFullScreen
        />

        <button className={styles.zoom_btn} onClick={toggleFullScreen}>
          <img src={ZoomIcon} alt="Avatar" />
        </button>
      </div>
    </div>
  );
}

export default GamePlay;
