import { useEffect, useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";

import styles from "./Popover.module.scss";

const Popover = ({
  id,
  open,
  anchorEl,
  onClose,
  anchorOrigin,
  transformOrigin,
  children,
}) => {
  const popoverRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      anchorEl &&
      !anchorEl.contains(event.target)
    ) {
      onClose();
    }
  };

  useLayoutEffect(() => {
    const { vertical: anchorVertical, horizontal: anchorHorizontal } =
      anchorOrigin;
    const { vertical: transformVertical, horizontal: transformHorizontal } =
      transformOrigin;

    const calculateTop = () => {
      if (!popoverRef.current) return;
      const popoverHeight = popoverRef.current.offsetHeight;
      const anchorTop = anchorEl.offsetTop;
      const anchorBottom = anchorEl.offsetTop + anchorEl.offsetHeight;
      const isAnchorVerticalTop = anchorVertical === "top";
      const isTransformVerticalTop = transformVertical === "top";
      const isTransformVerticalBottom = transformVertical === "bottom";

      let top;
      if (isAnchorVerticalTop) {
        top = anchorTop - (isTransformVerticalTop ? 0 : popoverHeight);
      } else {
        top =
          anchorBottom - (isTransformVerticalBottom ? popoverHeight : 0) + 5;
      }
      return `${top}px`;
    };

    const calculateLeft = () => {
      if (!popoverRef.current) return;

      const popoverWidth = popoverRef.current.offsetWidth;
      const anchorLeft = anchorEl.offsetLeft;
      const anchorRight = anchorEl.offsetLeft + anchorEl.offsetWidth;
      const isAnchorHorizontalLeft = anchorHorizontal === "left";
      const isTransformHorizontalLeft = transformHorizontal === "left";
      const isTransformHorizontalRight = transformHorizontal === "right";

      let left;
      if (isAnchorHorizontalLeft) {
        left = anchorLeft - (isTransformHorizontalLeft ? 0 : popoverWidth);
      } else {
        left = anchorRight - (isTransformHorizontalRight ? popoverWidth : 0);
      }

      return `${left}px`;
    };

    const top = calculateTop();
    const left = calculateLeft();

    if (popoverRef.current) {
      popoverRef.current.style.position = "absolute";
      popoverRef.current.style.top = top;
      popoverRef.current.style.left = left;
      popoverRef.current.style.transformOrigin = `${transformHorizontal} ${transformVertical}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popoverRef, anchorEl]);

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, anchorEl]);

  if (!open || !anchorEl) {
    return null;
  }

  return (
    <div ref={popoverRef} id={id} className={styles.popover_content}>
      {children}
    </div>
  );
};

Popover.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  anchorEl: PropTypes.instanceOf(Element),
  onClose: PropTypes.func.isRequired,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(["top", "bottom"]),
    horizontal: PropTypes.oneOf(["left", "right"]),
  }),
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(["top", "bottom", "center"]),
    horizontal: PropTypes.oneOf(["left", "right", "center"]),
  }),
  children: PropTypes.node.isRequired,
};

Popover.defaultProps = {
  open: false,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
};

export default Popover;
