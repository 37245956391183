import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false, // Trạng thái loading
};

export const loaderSlice = createSlice({
  name: "loader", // Tên slice
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload; // Cập nhật trạng thái loading
    },
  },
});

// Xuất action để sử dụng
export const { setLoading } = loaderSlice.actions;

// Xuất reducer
export default loaderSlice.reducer;
