export const LANGUAGE_CODES = {
  vi: "vi",
  ja: "ja",
  en: "en",
};

export const LANGUAGE_NAMES = {
  vi: "Việt Nam",
  ja: "日本語",
  en: "English",
};
