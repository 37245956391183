import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Lottie from "react-lottie";

import styles from "./LoaderContext.module.scss";

const LoaderContext = createContext();

export const useLoader = () => useContext(LoaderContext);

export const LoaderProvider = ({ children }) => {
  const isLoading = useSelector((state) => state.loaderRedux.isLoading);

  const [animationData, setAnimationData] = useState(null);

  const [fadeOut, setFadeOut] = useState(false);
  const [wasLoading, setWasLoading] = useState(false); // Track previous loading state
  useEffect(() => {
    // Load Lottie JSON file from public folder
    fetch(`${process.env.PUBLIC_URL}/loading.json`)
      .then((response) => response.json())
      .then((data) => setAnimationData(data))
      .catch((error) =>
        console.error("Error loading Lottie animation:", error)
      );
  }, []);
  useEffect(() => {
    if (isLoading) {
      // When loading starts, ensure no fade-out and set wasLoading to true
      setFadeOut(false);
      setWasLoading(true);
    } else if (wasLoading) {
      // When loading ends and it was previously loading, start the fade-out
      const timeoutId = setTimeout(() => {
        setFadeOut(true); // Trigger fade-out effect
      }, 500); // Adjust the delay before fading out

      // Cleanup timeout if the component unmounts or isLoading changes
      return () => clearTimeout(timeoutId);
    }
  }, [isLoading, wasLoading]);

  useEffect(() => {
    if (!isLoading && fadeOut) {
      // Reset wasLoading when fadeOut completes
      const timeoutId = setTimeout(() => {
        setWasLoading(false);
      }, 500); // Adjust to match the fade-out duration

      return () => clearTimeout(timeoutId);
    }
  }, [fadeOut, isLoading]);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // Dữ liệu hoạt ảnh từ tệp JSON
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice", // Điều chỉnh tỉ lệ của hoạt ảnh
    },
  };
  return (
    <LoaderContext.Provider value={{}}>
      {children}
      {(isLoading || wasLoading) && (
        <div className={`${styles.overlay} ${fadeOut ? styles.fadeOut : ""}`}>
          <div className={`${styles.loader} ${fadeOut ? styles.fadeOut : ""}`}>
            <Lottie options={defaultOptions} height={200} width={200} />
          </div>
        </div>
      )}
    </LoaderContext.Provider>
  );
};
