import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

const initialState = {
  patients: null,
  patient: null,
  metadataPatientsPage: null,
};

const DATA_SAMPLE = {
  pagination: {
    totalResult: 2,
    limit: 20,
    page: 1,
    totalPage: 1,
  },
  data: [
    {
      id: "user1",
      name: "Benh nhan 1",
      phone: "0123456555",
      gender: "1",
      age: 50,
      healthStatus: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      medicalHistory:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    },
    {
      id: "user2",
      name: "Benh nhan 2",
      phone: "0123456555",
      gender: "2",
      age: 40,
      healthStatus: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      medicalHistory:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    },
  ],
};

export const patientsSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPatients.pending, (state, action) => {})
      .addCase(getPatients.fulfilled, (state, action) => {
        state.patients = action.payload.data;
        state.metadataPatientsPage = action.payload.pagination;
      })
      .addCase(getPatients.rejected, (state, action) => {
        state.patients = DATA_SAMPLE.data;
        state.metadataPatientsPage = DATA_SAMPLE.pagination;
      })
      .addCase(getPatient.pending, (state, action) => {})
      .addCase(getPatient.fulfilled, (state, action) => {
        state.patient = action.payload.data;
      })
      .addCase(getPatient.rejected, (state, action) => {
        state.patient = {
          id: "user2",
          name: "Benh nhan 2",
          phone: "0123456555",
          gender: "2",
          age: 40,
          healthStatus:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
          medicalHistory:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
        };
      });
  },
});

export const getPatients = createAsyncThunk(
  "patients/getPatients",
  async () => {
    try {
      const respone = await axiosInstance.get(`/patients`, {});
      return respone.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getPatient = createAsyncThunk(
  "patients/getPatient",
  async (params) => {
    try {
      const { patientId } = params;

      const respone = await axiosInstance.get(`/patient/${patientId}`, {});
      return respone.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updatePatient = createAsyncThunk(
  "patients/updatePatient",
  async (params) => {
    try {
      const { patientId, doctorId } = params;
      const response = await axiosInstance.put(`/user/${patientId}`, {
        doctorId: doctorId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export default patientsSlice.reducer;
