import axios from "axios";

// Tạo instance của Axios
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const setupAxios = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      // Lấy token từ localStorage
      const mi01Auth = localStorage.getItem("mi01_auth");

      // Nếu có token, thêm accessToken vào header Authorization
      if (mi01Auth) {
        const { accessToken } = JSON.parse(mi01Auth); // Parse and get accessToken
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      }
      return config;
    },
    (error) => {
      // Xử lý lỗi trong request
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (response) => {
      // Return response if successful
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        // Xóa token và chuyển hướng về trang đăng nhập
        localStorage.removeItem("mi01_auth");
        window.location.href = "/login"; // Chuyển hướng về trang đăng nhập
      }
      return Promise.reject(error);
    }
  );
};

export default axiosInstance;
