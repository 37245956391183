import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import debounce from "debounce";

import styles from "./Dashboard.module.scss";

import {
  getGameConfigsOfUser,
  getGames,
  updateGameConfigOfUser,
} from "appdata/games/gamesSlice";
import CognitiveIcon from "assets/icons/cognitive.svg";
import NeckIcon from "assets/icons/neck.svg";
import NoDataImg from "assets/img/no-data.webp";
import PaginationCus from "components/PaginationCus/PaginationCus";
import { CATEGORIES } from "helps/Categories";
import { UNITS, UNIT_CONFIG } from "helps/UnitConfig";
import { toastConfig } from "utils/toastConfig";

const categoryIcons = {
  cognitive: CognitiveIcon,
  neck: NeckIcon,
};

const _games = [
  {
    configName: "puzzles",
    src: require("assets/img/puzzles_thumb.webp"),
    srcLarge: require("assets/img/puzzles.webp"),
  },
  {
    configName: "tmt_a",
    src: require("assets/img/tmt_A_thumb.webp"),
    srcLarge: require("assets/img/tmt_A.webp"),
  },
  {
    configName: "tmt_b",
    src: require("assets/img/tmt_B_thumb.webp"),
    srcLarge: require("assets/img/tmt_B.webp"),
  },
  {
    configName: "neck_throw",
    src: require("assets/img/headUp&Down_thumb.webp"),
    srcLarge: require("assets/img/headUp&Down.webp"),
  },
  {
    configName: "math",
    src: require("assets/img/add&subtract_thumb.webp"),
    srcLarge: require("assets/img/add&subtract.webp"),
  },
];

const PAGE_SIZE = 8;

function Dashboard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchRef = useRef();
  const leftRef = useRef(null);
  const rightRef = useRef(null);
  const testRef = useRef(null);

  const myselfRedux = useSelector((state) => state.myselfRedux);
  const gamesRedux = useSelector((state) => state.gamesRedux);

  const [currentPage, setCurrentPage] = useState(1);

  const [selectedCategory, setSelectedCategory] = useState(CATEGORIES.ALL);
  const [selectedGame, setSelectedGame] = useState(gamesRedux.games?.[0]);
  const [config, setConfig] = useState(null);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [valueSearch, setValueSearch] = useState("");

  const [isConfigGamePopupOpen, setIsConfigGamePopupOpen] = useState(false);

  const playNow = () => {
    dispatch(
      updateGameConfigOfUser({
        gameId: selectedGame.id,
        config: {
          config: config,
        },
      })
    )
      .unwrap()
      .then((data) => {
        navigate(
          `/gameplay?game=${selectedGame.configName}&userID=${myselfRedux.me.username}`
        );
      })
      .catch((error) => {
        toast.error("Error !", toastConfig);
      });
    return;
  };

  const handleInputChange =
    (key, min = null, max = null) =>
    (event) => {
      let { value } = event.target;

      // Kiểm tra nếu key là "isHeadDownMode" thì cần chuyển giá trị thành boolean
      if (key === "isHeadDownMode") {
        value = value === "true"; // Chuyển giá trị thành boolean (true/false)
      } else {
        value = Number(value); // Nếu không, chuyển giá trị thành số

        // Kiểm tra giá trị min và max nếu được truyền vào
        if (min !== null && value < min) {
          value = min; // Đặt lại về min nếu nhỏ hơn
        }
        if (max !== null && value > max) {
          value = max; // Đặt lại về max nếu lớn hơn
        }
      }

      setConfig((prevConfig) => ({
        ...prevConfig,
        [key]: value,
      }));
    };

  const handleGameClick = (game) => {
    setSelectedGame(game);
  };

  useEffect(() => {
    if (!gamesRedux.games || !gamesRedux.gameConfigsOfUser) return;
    setConfig(
      gamesRedux.gameConfigsOfUser[`${selectedGame.configName}_config`]
    );
  }, [selectedGame]);

  useEffect(() => {
    const getGamesDebounce = debounce(() => {
      dispatch(
        getGames({
          page: 1,
          limit: PAGE_SIZE,
          name: valueSearch,
          type: selectedCategory !== "all" ? selectedCategory : "",
        })
      );
    }, 300);

    getGamesDebounce();
    return () => getGamesDebounce.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, valueSearch]);

  useEffect(() => {
    if (!gamesRedux.games) return;

    setSelectedGame(gamesRedux.games[0]);

    if (!gamesRedux.gameConfigsOfUser) return;

    setConfig(
      gamesRedux.gameConfigsOfUser[`${gamesRedux.games[0].configName}_config`]
    );
  }, [gamesRedux]);

  useEffect(() => {
    dispatch(getGames({ page: 1, limit: PAGE_SIZE }));
    dispatch(getGameConfigsOfUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.dashboard_wrapper}>
      {!isConfigGamePopupOpen && (
        <div className={styles.tool_box}>
          <div className={`${styles.category_wrapper}`}>
            {Object.keys(CATEGORIES).map((key) => {
              const categoryValue = CATEGORIES[key];
              const Icon = categoryIcons[categoryValue];

              return (
                <span
                  key={key}
                  className={`${
                    selectedCategory === categoryValue
                      ? `text_gradient ${styles.selected_category}`
                      : ""
                  }`}
                  onClick={() => {
                    setSelectedCategory(categoryValue);
                  }}
                >
                  {categoryValue !== "all" && Icon && (
                    <img src={Icon} alt={categoryValue} />
                  )}
                  {t(`game_categories.${categoryValue}`)}
                </span>
              );
            })}
          </div>

          <div
            className={`${styles.search_bar} ${
              isSearchActive ? styles.active : ""
            }`}
            onClick={() => {
              searchRef.current.focus();
              setIsSearchActive(true);
            }}
          >
            <div className={styles.search_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001a1 1 0 0 0 .152 1.318l3.85 3.85a1 1 0 0 0 1.415-1.415l-3.85-3.85a1 1 0 0 0-1.318-.152zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </div>
            <input
              ref={searchRef}
              type="text"
              placeholder={t("common_text.search")}
              className={styles.search_input}
              value={valueSearch}
              onChange={(e) => {
                const newValue = e.target.value;
                setValueSearch(newValue);
                setIsSearchActive(newValue.trim() !== "");
              }}
              onFocus={() => setIsSearchActive(true)}
              onBlur={() => {
                if (valueSearch.trim() === "") {
                  setIsSearchActive(false);
                }
              }}
            />
          </div>
        </div>
      )}
      {!isConfigGamePopupOpen && (
        <div className={styles.dashboard_container}>
          {selectedGame && (
            <div className={styles.game_list}>
              {gamesRedux.games &&
                gamesRedux.games.map((game, index) => (
                  <div
                    key={index}
                    className={`${styles.game_item} ${
                      selectedGame?.name === game.name ? styles.selected : ""
                    }`}
                    onClick={() => handleGameClick(game)}
                  >
                    <div className={styles.image_container}>
                      <img
                        src={
                          _games.find((el) => el.configName === game.configName)
                            .src
                        }
                        alt={game.name}
                        className={styles.game_image}
                      />
                      <div className={styles.overlay}>
                        <button
                          className={`${styles.config_button} rounded`}
                          onClick={() => {
                            handleGameClick(game);
                            setIsConfigGamePopupOpen(true);
                          }}
                        >
                          {t("common_text.configPlay")}
                        </button>
                      </div>
                    </div>
                    <div
                      className={`
                    ${selectedGame?.name === game.name ? "text_gradient" : ""}
                    ${styles.game_name}`}
                    >
                      {game.name}
                    </div>
                  </div>
                ))}
            </div>
          )}

          {gamesRedux.metadataGamesPage && (
            <PaginationCus
              metadataPage={gamesRedux.metadataGamesPage}
              onClick={(pageParam) => {
                setCurrentPage(pageParam.page);
              }}
              styles={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1vw",
              }}
            />
          )}
          {!selectedGame && (
            <div className={styles.table_nodata}>
              <img src={NoDataImg} alt="nodata"></img>
              <span>{t("common_text.noData")}</span>
            </div>
          )}
        </div>
      )}

      {isConfigGamePopupOpen && (
        <div className={styles.config_game_container}>
          <div ref={leftRef} className={styles.left}>
            <div className={styles.config_description}>
              <h2 className="text_gradient">{t("common_text.description")}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(`game_description.${selectedGame.configName}`),
                }}
              />
            </div>

            <div className={styles.config_tab}>
              <h2 className="text_gradient">
                {t("common_text.configuration")}
              </h2>

              <div className={styles.config_scroll}>
                {selectedGame.configName === "neck_throw" && (
                  <>
                    <div className={styles.config_item}>
                      <label>{t(`game_config.durationSecond`)}</label>
                      <div className={styles.input_wrapper}>
                        <input
                          type="number"
                          value={config.durationSecond}
                          onChange={handleInputChange("durationSecond", 30)}
                          className="size_medium"
                        />
                        <span className={styles.unit}>{t("unit.seconds")}</span>
                      </div>
                    </div>
                    <div className={styles.config_item}>
                      <label>{t(`game_config.isHeadDownMode`)}</label>
                      <div className={styles.input_wrapper}>
                        <select
                          value={config.isHeadDownMode}
                          onChange={handleInputChange("isHeadDownMode")}
                          className="size_medium"
                        >
                          <option value={true}>
                            {t("game_config.goalHeadDown")}
                          </option>
                          <option value={false}>
                            {t("game_config.goalHeadUp")}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className={styles.config_item}>
                      <label>{t(`game_config.repetitions`)}</label>
                      <div className={styles.input_wrapper}>
                        <input
                          type="number"
                          value={
                            config.isHeadDownMode
                              ? config.goalHeadDown
                              : config.goalHeadUp
                          }
                          onChange={handleInputChange(
                            config.isHeadDownMode
                              ? "goalHeadDown"
                              : "goalHeadUp",
                            1
                          )}
                          className="size_medium"
                        />
                        <span className={styles.unit}>{t("unit.count")}</span>
                      </div>
                    </div>
                  </>
                )}
                {selectedGame.configName !== "neck_throw" &&
                  Object.entries(config).map(([key, value]) => {
                    console.log(config);
                    if (key === "isHeadDownMode") {
                      return (
                        <div className={styles.config_item} key={key}>
                          <label>{t(`game_config.${key}`)}</label>
                          <div className={styles.input_wrapper}>
                            <select
                              value={value}
                              onChange={handleInputChange(key)}
                              className="size_medium"
                            >
                              <option value={true}>
                                {t("game_config.goalHeadDown")}
                              </option>
                              <option value={false}>
                                {t("game_config.goalHeadUp")}
                              </option>
                            </select>
                          </div>
                        </div>
                      );
                    }
                    if (key === "calculationDifficulty") {
                      return (
                        <div className={styles.config_item} key={key}>
                          <label>{t(`game_config.${key}`)}</label>
                          <div className={styles.input_wrapper}>
                            <select
                              value={value}
                              onChange={handleInputChange(key)}
                              className="size_medium"
                            >
                              <option value={1}>
                                {`${t("game_config.calculationDifficulty")} 1`}
                              </option>
                              <option value={2}>
                                {`${t("game_config.calculationDifficulty")} 2`}
                              </option>
                              <option value={3}>
                                {`${t("game_config.calculationDifficulty")} 3`}
                              </option>
                            </select>
                          </div>
                        </div>
                      );
                    }

                    if (key === "maxNumber") {
                      return (
                        <div className={styles.config_item} key={key}>
                          <label>{t(`game_config.${key}`)}</label>
                          <div className={styles.input_wrapper}>
                            <input
                              type="number"
                              value={value}
                              onChange={handleInputChange(key, 2, 50)}
                              className="size_medium"
                            />
                            <span className={styles.unit}>
                              {UNIT_CONFIG[key] !== UNITS.NONE &&
                                `(${t(`unit.${UNIT_CONFIG[key]}`)})`}
                            </span>
                          </div>
                        </div>
                      );
                    }

                    if (key === "countOfTurn") {
                      return (
                        <div className={styles.config_item} key={key}>
                          <label>{t(`game_config.${key}`)}</label>
                          <div className={styles.input_wrapper}>
                            <input
                              type="number"
                              value={value}
                              onChange={handleInputChange(key, 1)}
                              className="size_medium"
                            />
                            <span className={styles.unit}>
                              {UNIT_CONFIG[key] !== UNITS.NONE &&
                                `(${t(`unit.${UNIT_CONFIG[key]}`)})`}
                            </span>
                          </div>
                        </div>
                      );
                    }

                    if (key === "timePerTurn" || key === "duration") {
                      return (
                        <div className={styles.config_item} key={key}>
                          <label>{t(`game_config.${key}`)}</label>
                          <div className={styles.input_wrapper}>
                            <input
                              type="number"
                              value={value}
                              onChange={handleInputChange(key, 30)}
                              className="size_medium"
                            />
                            <span className={styles.unit}>
                              {UNIT_CONFIG[key] !== UNITS.NONE &&
                                `(${t(`unit.${UNIT_CONFIG[key]}`)})`}
                            </span>
                          </div>
                        </div>
                      );
                    }

                    if (key !== "timePerTurn") {
                      return (
                        <div className={styles.config_item} key={key}>
                          <label>{t(`game_config.${key}`)}</label>
                          <div className={styles.input_wrapper}>
                            <input
                              type="number"
                              value={value}
                              onChange={handleInputChange(key)}
                              className="size_medium"
                            />
                            <span className={styles.unit}>
                              {UNIT_CONFIG[key] !== UNITS.NONE &&
                                `(${t(`unit.${UNIT_CONFIG[key]}`)})`}
                            </span>
                          </div>
                        </div>
                      );
                    }

                    return null;
                  })}
              </div>
            </div>
          </div>

          <div ref={rightRef} className={styles.right}>
            <div className={styles.overlay}></div>
            <img
              ref={testRef}
              src={
                _games.find((el) => el.configName === selectedGame.configName)
                  .srcLarge
              }
              alt="img"
            />
            <div className={styles.float}>
              <span className={styles.gameName}>
                {selectedGame.displayName}
              </span>
              <button
                className={`${styles.play_button} button_gradient_blue rounded size_large`}
                onClick={playNow}
              >
                {t("common_text.playNow")}
              </button>
            </div>
          </div>
          <button
            className={`${styles.close_btn} size_1 rounded`}
            onClick={() => {
              setIsConfigGamePopupOpen(false);
            }}
          >
            &times;
          </button>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
