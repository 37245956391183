import { configureStore } from "@reduxjs/toolkit";

import gamesSlice from "./games/gamesSlice";
import loaderSlice from "./loader/loaderSlice";
import myselfSlice from "./myself/myselfSlice";
import patientsSlice from "./patients/patientsSlice";

export const store = configureStore({
  reducer: {
    gamesRedux: gamesSlice,
    myselfRedux: myselfSlice,
    patientsRedux: patientsSlice,
    loaderRedux: loaderSlice,
  },
});
