import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

const initialState = {
  me: null,
};

export const myselfSlice = createSlice({
  name: "me",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.me = action.payload;
    },
    clearUser: (state) => {
      state.me = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMe.pending, (state, action) => {})
      .addCase(getMe.fulfilled, (state, action) => {
        state.me = action.payload.data;
      })
      .addCase(getMe.rejected, (state, action) => {})
      .addCase(updateMe.pending, (state, action) => {})
      .addCase(updateMe.fulfilled, (state, action) => {
        state.me = { ...state.me, ...action.payload.data };
      })
      .addCase(updateMe.rejected, (state, action) => {});
  },
});

export const getMe = createAsyncThunk("me/getMe", async () => {
  try {
    const respone = await axiosInstance.get(`/users/me`);
    return respone.data;
  } catch (error) {
    throw new Error(error);
  }
});

export const updateMe = createAsyncThunk(
  "me/updateMe",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`/users/me`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    } finally {
    }
  }
);

export const { setUser, clearUser } = myselfSlice.actions;

export default myselfSlice.reducer;
