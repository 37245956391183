import { LoaderProvider } from "contexts/loader-context/LoaderContext";
import { PopupProvider } from "contexts/popup-context/PopupContext";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import AppRoutes from "routes/AppRoutes";

import "react-toastify/dist/ReactToastify.css";

import { store } from "./appdata/store";

import "./App.css";

function App() {
  return (
    <Provider store={store}>
      <LoaderProvider>
        <PopupProvider>
          <AppRoutes></AppRoutes>
        </PopupProvider>
      </LoaderProvider>
      <ToastContainer />
    </Provider>
  );
}

export default App;
