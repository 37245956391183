import Popover from "components/Popover/Popover";
import { usePopup } from "contexts/popup-context/PopupContext";
import { LANGUAGE_CODES, LANGUAGE_NAMES } from "helps/Languages";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import ArrowDownIcon from "assets/icons/arrow-down.svg";
import CheckIcon from "assets/icons/check.svg";
import LanguageIcon from "assets/icons/language.svg";
import SwitchUserIcon from "assets/icons/switch-user.svg";
import UserLogoIcon from "assets/icons/user.svg";
import MiraboImg from "assets/img/mirabo-logo.webp";

import { updateMe } from "appdata/myself/myselfSlice";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Header.module.scss";

function Header() {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setIsSwitchUserPopupOpen, setIsUserInfomationPopupOpen } = usePopup();

  const me = useSelector((state) => state.myselfRedux.me);

  const [isDropdownLanguageOpen, setIsDropdownLanguageOpen] = useState(false);
  const [anchorElRegion, setAnchorElRegion] = useState(null);

  const handleRegionClick = (e) => {
    setAnchorElRegion(e.currentTarget);
    setIsDropdownLanguageOpen(true);
  };

  const handleCloseRegion = () => {
    setAnchorElRegion(null);
    setIsDropdownLanguageOpen(false);
  };

  const onChangeLanguage = (value) => {
    dispatch(
      updateMe({
        language: value === "vi" ? "vi-VN" : value,
      })
    );
    i18n.changeLanguage(value);
    localStorage.setItem("mi01_la", value);
  };

  const openRegion = Boolean(anchorElRegion);
  const idRegion = openRegion ? "region-popover" : undefined;

  return (
    <div className={styles.header_wrapper}>
      <div className={styles.left}>
        <img
          src={MiraboImg}
          alt="Mirabo"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard");
          }}
        />
      </div>

      <div className={styles.right}>
        {location.pathname !== "/gameplay" && (
          <>
            <button
              className={`${styles.language_btn} rounded`}
              onClick={handleRegionClick}
            >
              <img
                style={{
                  objectFit: "cover",
                }}
                src={LanguageIcon}
                alt="Globe icon"
              />
              <span className={`size_small`} style={{ textWrap: "nowrap" }}>
                {LANGUAGE_NAMES[localStorage.getItem("mi01_la") ?? "ja"]}
              </span>
              <img src={ArrowDownIcon} alt="t" />
            </button>

            <Popover
              id={idRegion}
              open={openRegion}
              anchorEl={anchorElRegion}
              onClose={handleCloseRegion}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div
                className={`${styles.language_options} ${
                  isDropdownLanguageOpen ? styles.open : styles.closed
                }`}
              >
                {Object.entries(LANGUAGE_CODES).map(([key, value]) => (
                  <button
                    key={key}
                    className={`${styles.language_option} ${
                      i18n.language === value ? styles.selected : ""
                    }`}
                    onClick={() => {
                      onChangeLanguage(value);
                    }}
                  >
                    <span>{LANGUAGE_NAMES[value]}</span>
                    {i18n.language === value && (
                      <img src={CheckIcon} alt="icon" />
                    )}
                  </button>
                ))}
              </div>
            </Popover>

            <div className={styles.line}></div>
          </>
        )}

        <button
          className={`${styles.switch_user_btn}`}
          onClick={() => {
            setIsSwitchUserPopupOpen(true);
          }}
        >
          <img
            style={{
              objectFit: "cover",
              maxWidth: "100%",
            }}
            src={SwitchUserIcon}
            alt="Avatar"
          />
        </button>

        <div className={styles.line}></div>

        <button
          className={`${styles.profile_btn} rounded`}
          onClick={() => {
            setIsUserInfomationPopupOpen(true);
          }}
        >
          <img
            style={{
              objectFit: "cover",
            }}
            src={UserLogoIcon}
            alt="Avatar"
          />
          <span className={`size_small`} style={{ textWrap: "nowrap" }}>
            {`ID: ${me?.username ?? ""}`}
          </span>
          <img src={ArrowDownIcon} alt="t" />
        </button>
      </div>
    </div>
  );
}

export default Header;
