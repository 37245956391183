export const UNITS = {
  SECONDS: "seconds",
  COUNT: "count",
  NONE: "none",
};

export const UNIT_CONFIG = {
  timePerTurn: UNITS.SECONDS,
  countOfTurn: UNITS.COUNT,
  maxNumber: UNITS.NONE,
  duration: UNITS.SECONDS,
  goalHeadUp: UNITS.COUNT,
  goalHeadDown: UNITS.COUNT,
  durationSecond: UNITS.SECONDS,
  calculationDifficulty: UNITS.NONE,
};
