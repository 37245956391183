import { ConfigProvider, Pagination } from "antd";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const PaginationCus = ({ metadataPage, onClick = () => {}, styles }) => {
  const { currentPage, total, limit } = metadataPage;
  const [paginationSize, setPaginationSize] = useState({
    fontSize: 14,
    itemSize: 32,
  });

  const handleClickPageButton = (page) => {
    const pageParam = { page: page, pageSize: limit };
    onClick(pageParam);
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width >= 3600) {
        setPaginationSize({ fontSize: 28, itemSize: 70 });
      } else if (width >= 2400) {
        setPaginationSize({ fontSize: 18, itemSize: 40 });
      } else {
        setPaginationSize({ fontSize: 14, itemSize: 32 });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{ ...styles }}>
      <ConfigProvider
        theme={{
          token: {
            fontSize: paginationSize.fontSize,
          },
          components: {
            Pagination: {
              itemSize: paginationSize.itemSize,
            },
          },
        }}
      >
        <Pagination
          currentPage={currentPage + 1}
          defaultCurrent={1}
          total={total}
          onChange={handleClickPageButton}
          align="end"
          hideOnSinglePage
        />
      </ConfigProvider>
    </div>
  );
};

PaginationCus.propTypes = {
  metadataPage: PropTypes.any,
  onClick: PropTypes.func,
  styles: PropTypes.any,
};

export default PaginationCus;
